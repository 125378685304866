import { vehiclesApiTemplateSlice as api } from '../state/vehicles/vehiclesApiTemplateSlice';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVehicles: build.query<GetVehiclesApiResponse, GetVehiclesApiArg>({
      query: () => ({ url: `/vehicles` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetVehiclesApiResponse = /** status 200 OK */ VehiclesList;
export type GetVehiclesApiArg = void;
export type PaymentMethodExpiryDate = string;
export type PaymentMethod = {
  id: string;
  type: PaymentMethodType;
  paymentPartner: PaymentPartnerType;
  lastFourDigits: string;
  expiry: PaymentMethodExpiryDate;
};
export type PaymentMethodAssignment = {
  /** Reference to `vehicle.paymentMethods[].id`. */
  paymentMethodId: string;
};
export type ServiceActivation = {
  enabled: boolean;
  serviceType: ServiceType;
  paymentMethodAssignments: PaymentMethodAssignment[];
};
export type Vehicle = {
  id: string;
  name: string;
  vin: string;
  licensePlate?: string;
  type: VehicleType;
  /** Indicates that the vehicle was not activated for SimplePay yet. */
  enabled: boolean;
  group_ids: string[];
  brand: string;
  paymentMethods: PaymentMethod[];
  serviceActivations: ServiceActivation[];
};
export type VehiclesList = {
  items: Vehicle[];
};
export enum VehicleType {
  Truck = 'TRUCK',
  Bus = 'BUS',
  Van = 'VAN',
  Trailer = 'TRAILER',
  Car = 'CAR',
}
export enum PaymentMethodType {
  FuelCard = 'FUEL_CARD',
}
export enum PaymentPartnerType {
  MockFuelingPayment = 'MOCK_FUELING_PAYMENT',
  UtaPayment = 'UTA_PAYMENT',
}
export enum ServiceType {
  Fueling = 'FUELING',
  Breakdown = 'BREAKDOWN',
  Parking = 'PARKING',
  Washing = 'WASHING',
}
export const { useGetVehiclesQuery } = injectedRtkApi;
