import { SelectOption } from '@rio-cloud/rio-uikit/components/selects/BaseSelectDropdown';
import Switch from '@rio-cloud/rio-uikit/Switch';
import { CustomFormattedMessage, useCustomIntl } from '../../../i18n/i18n';
import Select from '@rio-cloud/rio-uikit/Select';
import Button from '@rio-cloud/rio-uikit/Button';
import {
  BookingDetails,
  getParkingBookingData,
  setBookingDetails,
} from '../state/parkingBookingSlice';
import {
  locationDetailsRoute,
  PARKING_BOOKING_SUMMARY_ROUTE,
} from '../../../routing/routes';
import { useAppDispatch, useAppSelector } from '../../../state/store';
import { useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormField } from '../../../components/form/FormField';
import moment, { Moment } from 'moment-timezone';
import { OptionalLabel } from '../../../components/form/OptionalLabel';
import { SchedulerDetails } from './SchedulerDetails';
import { DateUtils } from '../../../components/utils/DateUtils';
import {
  InstructionLanguage,
  useLazyGetParkingAvailabilityQuery,
} from '../../../codegen/booking';
import {
  LANGUAGE_TO_FLAG,
  LANGUAGE_TO_TRANSLATION_KEY,
} from '../../../i18n/enumMappings';
import { useGetVehiclesQuery } from '../../../codegen/vehicles';
import { useEffect, useMemo } from 'react';
import { InfoPopup } from '../../../components/InfoPopup';
import { ParkingAvailability } from './ParkingAvailability';

export type Form = {
  vehicle: {
    id: string;
    label: string;
    licensePlate: string;
  };
  trailer: boolean;
  trailerLicensePlate?: string;
  scheduler: {
    arrivalDate?: Moment;
    arrivalTime?: Moment;
    departureDate?: Moment;
    departureTime?: Moment;
  };
  driver?: string;
  tripReference?: string;
  entryInstructionsLanguage: SelectOption;
};

const parseMoment = (value?: string): Moment | undefined => {
  return value ? moment.parseZone(value) : undefined;
};

export const BookingDetailsForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();
  const { locationData } = useAppSelector(getParkingBookingData);
  const locationId = locationData?.locationId!;

  const {
    isLoading,
    data: vehicles,
    error: vehiclesError,
  } = useGetVehiclesQuery();

  const supportedLanguages: SelectOption[] = Object.keys(InstructionLanguage)
    .map(
      (language) =>
        InstructionLanguage[language as keyof typeof InstructionLanguage],
    )
    .sort((a, b) => {
      const aTranslated = intl.formatMessage({
        id: LANGUAGE_TO_TRANSLATION_KEY[a],
      });
      const bTranslated = intl.formatMessage({
        id: LANGUAGE_TO_TRANSLATION_KEY[b],
      });

      return aTranslated.localeCompare(bTranslated);
    })
    .map((language) => {
      const label = (
        <>
          <span className={'margin-right-3'}>{LANGUAGE_TO_FLAG[language]}</span>
          <CustomFormattedMessage id={LANGUAGE_TO_TRANSLATION_KEY[language]} />
        </>
      );

      return {
        id: language,
        label,
      };
    });

  const english = supportedLanguages.find(
    (e) => e.id.toLowerCase() === InstructionLanguage.En.toLowerCase(),
  );

  const parkingBookingData = useAppSelector(getParkingBookingData);
  const timezone =
    parkingBookingData.locationData?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const arrival = parseMoment(parkingBookingData.arrival);
  const departure = parseMoment(parkingBookingData.departure);

  const form = useForm<Form>({
    defaultValues: {
      vehicle: parkingBookingData.vehicleData
        ? {
            id: parkingBookingData.vehicleData.id,
            label: parkingBookingData.vehicleData.name,
          }
        : undefined,
      trailer: parkingBookingData.trailerData?.trailerEnabled,
      trailerLicensePlate: parkingBookingData.trailerData?.trailerLicensePlate,
      scheduler: {
        arrivalDate: arrival,
        arrivalTime: arrival,
        departureDate: departure,
        departureTime: departure,
      },
      driver: parkingBookingData.driverName,
      tripReference: parkingBookingData.tripReference,
      entryInstructionsLanguage: english,
    },
  });

  const { handleSubmit, control, register, formState, watch } = form;

  const errors = formState.errors;
  const hasFormErrors = Object.keys(errors).length > 0;
  const hasSchedulerErrors = errors.scheduler;

  const hasTrailer = watch('trailer');

  const arrivalDate = watch('scheduler.arrivalDate');
  const arrivalTime = watch('scheduler.arrivalTime');
  const arrivalAsString = DateUtils.parseDateTime(
    timezone,
    arrivalDate,
    arrivalTime,
  );
  const departureDate = watch('scheduler.departureDate');
  const departureTime = watch('scheduler.departureTime');
  const departureAsString = DateUtils.parseDateTime(
    timezone,
    departureDate,
    departureTime,
  );
  const isParkingAvailabilityEnabled =
    arrivalAsString !== undefined &&
    departureAsString !== undefined &&
    !hasSchedulerErrors;

  const [
    getParkingAvailability,
    { isFetching, data: parkingAvailability, error: parkingAvailabilityError },
  ] = useLazyGetParkingAvailabilityQuery();

  const fetchParkingAvailability = () => {
    if (arrivalAsString && departureAsString && !hasSchedulerErrors) {
      getParkingAvailability({
        locationId,
        arrivalTime: arrivalAsString,
        departureTime: departureAsString,
      });
    }
  };

  useEffect(() => {
    fetchParkingAvailability();
  }, [arrivalAsString, departureAsString, locationId, getParkingAvailability]);

  const isParkingAvailable =
    parkingAvailability?.available && !parkingAvailabilityError;

  const vehicleOptions: SelectOption[] = useMemo(
    () =>
      (vehicles?.items || [])
        .slice()
        .filter(
          (vehicle) =>
            vehicle.licensePlate &&
            vehicle.enabled &&
            vehicle.serviceActivations?.some(
              (activation) =>
                activation.serviceType === 'PARKING' && activation.enabled,
            ),
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((vehicle) => ({
          id: vehicle.id,
          label: vehicle.name,
          licensePlate: vehicle.licensePlate,
        })),
    [isLoading, vehicles],
  );

  const onSubmit: SubmitHandler<Form> = (formData: Form) => {
    const estimatedPrice = isParkingAvailable
      ? parkingAvailability.estimatedPrice
      : undefined;

    const bookingDetails: BookingDetails = {
      vehicleData: {
        id: formData.vehicle.id,
        name: formData.vehicle.label,
        licensePlate: formData.vehicle.licensePlate,
      },
      trailerData: {
        trailerEnabled: formData.trailer || false,
        trailerLicensePlate: formData.trailer
          ? formData.trailerLicensePlate
          : undefined,
      },
      arrival: DateUtils.parseDateTime(
        timezone,
        formData.scheduler.arrivalDate,
        formData.scheduler.arrivalTime,
      ),
      departure: DateUtils.parseDateTime(
        timezone,
        formData.scheduler.departureDate,
        formData.scheduler.departureTime,
      ),
      driverName: formData.driver,
      tripReference: formData.tripReference
        ? formData.tripReference
        : undefined,
      entryInstructionsLanguage: formData.entryInstructionsLanguage
        .id as InstructionLanguage,
      estimatedPrice,
    };

    dispatch(setBookingDetails(bookingDetails));
    navigate(PARKING_BOOKING_SUMMARY_ROUTE);
  };

  const toLocationDetails = () => {
    navigate(locationDetailsRoute(locationId));
  };

  const canSubmitForm =
    !hasFormErrors &&
    !vehiclesError &&
    (isParkingAvailabilityEnabled ? isParkingAvailable : true);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormField
        error={errors.vehicle}
        data-testid={'booking-details-form-vehicle'}
      >
        <label htmlFor="vehicle">
          <CustomFormattedMessage id={'common.label.vehicle'} />
        </label>
        <InfoPopup
          placement={'right-end'}
          data-testid={'booking-details-form-vehicle-info-icon'}
        >
          <CustomFormattedMessage
            id={'bookingDetailsPage.vehicle.noteText'}
            values={{
              link: (parts: string) => (
                <a
                  className={'text-decoration-underline'}
                  href={'https://administration.assets.rio.cloud/#/assets'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {parts}
                </a>
              ),
            }}
          />
        </InfoPopup>
        <Controller
          name={'vehicle'}
          render={({ field: { onChange, value } }) => (
            <Select
              id="vehicle"
              placeholder={
                <CustomFormattedMessage
                  id={'bookingDetailsPage.vehicle.placeholder'}
                />
              }
              options={vehicleOptions}
              useFilter
              value={value ? [value.id] : []}
              onChange={onChange}
            />
          )}
          control={control}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'common.errorMessage.required',
              }),
            },
          }}
        />
      </FormField>
      <div className={'margin-bottom-10'}>
        <Controller
          name={'trailer'}
          render={({ field: { onChange, value } }) => (
            <Switch
              checked={value}
              onChange={(toggleState) => {
                if (!toggleState) {
                  form.setValue('trailerLicensePlate', '');
                }
                onChange(toggleState);
              }}
            >
              <CustomFormattedMessage id={'common.label.trailer'} />
            </Switch>
          )}
          control={control}
        />
      </div>
      {hasTrailer && (
        <FormField
          error={errors.trailerLicensePlate}
          data-testid={'booking-details-form-trailer-license-plate'}
        >
          <label htmlFor="trailerLicensePlate">
            <CustomFormattedMessage id={'common.label.trailerLicensePlate'} />
          </label>
          <input
            id="trailerLicensePlate"
            type="text"
            className="form-control"
            {...register('trailerLicensePlate', {
              required: {
                value: true,
                message: intl.formatMessage({
                  id: 'common.errorMessage.required',
                }),
              },
            })}
          />
        </FormField>
      )}
      <SchedulerDetails
        form={form}
        timezone={timezone}
        errors={errors}
      />
      {isParkingAvailabilityEnabled && (
        <ParkingAvailability
          isFetching={isFetching}
          estimatedPrice={parkingAvailability?.estimatedPrice}
          hasError={parkingAvailabilityError}
          onRetryClick={fetchParkingAvailability}
        />
      )}
      <FormField
        error={errors.driver}
        data-testid={'booking-details-form-driver'}
      >
        <label htmlFor="driver">
          <CustomFormattedMessage id={'common.label.driver'} />
        </label>
        <input
          id="driver"
          type="text"
          className="form-control"
          {...register('driver', {
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'common.errorMessage.required',
              }),
            },
          })}
        />
      </FormField>
      <FormField
        error={errors.tripReference}
        data-testid={'booking-details-form-trip-reference'}
      >
        <label htmlFor="tripReference">
          <CustomFormattedMessage id={'common.label.tripReference'} />
          <OptionalLabel className={'padding-left-5'} />
        </label>
        <input
          id="tripReference"
          type="text"
          className="form-control"
          {...register('tripReference')}
        />
      </FormField>
      <FormField
        error={errors.entryInstructionsLanguage}
        className={'margin-bottom-20'}
        data-testid={'booking-details-form-entry-instruction-language'}
      >
        <label htmlFor="entryInstructionsLanguage">
          <CustomFormattedMessage
            id={'common.label.entryInstructionsLanguage'}
          />
        </label>
        <Controller
          name={'entryInstructionsLanguage'}
          render={({ field: { onChange, value } }) => (
            <Select
              id="entryInstructionsLanguage"
              options={supportedLanguages}
              hasError={!!errors.entryInstructionsLanguage}
              onChange={onChange}
              value={value ? [value.id] : []}
            />
          )}
          control={control}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'common.errorMessage.required',
              }),
            },
          }}
        />
      </FormField>
      <Button
        bsStyle="default"
        onClick={toLocationDetails}
        className={'margin-right-15'}
      >
        <CustomFormattedMessage id={'common.back'} />
      </Button>
      <Button
        bsStyle="primary"
        type={'submit'}
        disabled={!canSubmitForm}
      >
        <CustomFormattedMessage id={'common.next'} />
      </Button>
    </form>
  );
};
